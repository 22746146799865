import React, { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { useDispatch } from "react-redux";
import { mutateQuestion } from "../../../../store/reducers/appSlice";

const StandardMultipleAnswerQuestionComponent = ({
  currentQuestionIndex,
  question,
}) => {
  const [checkedState, setCheckedState] = useState({});

  const dispatch = useDispatch();

  const answerMultipleAnswerQuestion = (answer) => {
    if (question && !question?.hasOwnProperty("USER_ANSWERS")) {
      question.USER_ANSWERS = [];
    }

    const index = question.USER_ANSWERS.findIndex(
      (userAnswer) => userAnswer.ANSWER_ID === answer.ANSWER_ID
    );

    if (index >= 0) {
      question.USER_ANSWERS.splice(index, 1);
    } else {
      question.USER_ANSWERS.push(answer);
    }

    question.SKIPPED = false;
    question.ANSWERED = true;

    dispatch(
      mutateQuestion({
        index: currentQuestionIndex,
        question: question,
      })
    );
  };

  useEffect(() => {
    const initialState = question.ANSWER_OPTIONS.reduce((acc, answer) => {
      acc[answer.ANSWER_TEXT] = false;
      return acc;
    }, {});

    if (question.hasOwnProperty("USER_ANSWERS")) {
      question.USER_ANSWERS.forEach((userAnswer) => {
        initialState[userAnswer.ANSWER_TEXT] = true;
      });
    }

    setCheckedState(initialState);
  }, [currentQuestionIndex, question]);

  const handleCheckboxChange = (answer) => {
    const newCheckedState = {
      ...checkedState,
      [answer.ANSWER_TEXT]: !checkedState[answer.ANSWER_TEXT],
    };
    setCheckedState(newCheckedState);
    answerMultipleAnswerQuestion(answer);
  };

  return (
    <Box>
      <FormGroup>
        {question?.ANSWER_OPTIONS.map((answer) => (
          <FormControlLabel
            key={answer.ANSWER_TEXT}
            control={
              <Checkbox
                checked={!!checkedState[answer.ANSWER_TEXT]}
                onChange={() => handleCheckboxChange(answer)}
              />
            }
            label={answer.ANSWER_ID + ". " + answer.ANSWER_TEXT}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default StandardMultipleAnswerQuestionComponent;
