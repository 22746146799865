const aws_ccp = {
  id: "aws-ccp",
  name: "AWS Cloud Practitioner",
  description: "AWS Cloud Practitioner",
  icon: require("../../../../assets/aws-ccp.png"),
  questions: 65,
  time: 5400,
  exam_id: "CLF-C01",
  topics: [
    "Cloud-Concepts",
    "AWS-Global-Infrastructure",
    "AWS-Shared-Responsibility-Model",
    "AWS-Cloud-Security",
    "AWS-Identity-and-Access-Management",
    "AWS-Compliance-Programs",
    "Cost-Management",
    "Billing-and-Pricing",
    "AWS-Pricing-Models",
    "AWS-Free-Tier",
    "AWS-Support-Plans",
    "Architecting-on-AWS",
    "AWS-Compute-Services",
    "AWS-Storage-Services",
    "AWS-Database-Services",
    "AWS-Networking",
    "AWS-Monitoring-and-Logging",
    "AWS-Automation",
    "AWS-Developer-Tools",
    "AWS-Deployment-Services",
    "AWS-Management-Tools",
    "AWS-Analytics-Services",
    "AWS-AI-and-ML-Services",
    "AWS-IoT-Services",
    "AWS-Migration-and-Transfer-Services",
    "AWS-Serverless",
    "AWS-Edge-Services",
    "AWS-Application-Integration-Services",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "CLF-C02" },
      { name: "Number of Questions", value: "65 Questions" },
      {
        name: "Question Types",
        value: "Multiple Choice / Multiple Response",
      },
      { name: "Length of Test", value: "90 Minutes" },

      { name: "Passing Score", value: "700 (on a scale of 100-1000)" },
      { name: "Cost of Exam", value: "$100.00" },
      { name: "Validity", value: "3 Years" },
    ],
    domains: [
      {
        number: "1.0",
        name: "Cloud Concepts",
        percentage: "24%",
      },
      {
        number: "2.0",
        name: "Security and Compliance",
        percentage: "30%",
      },
      {
        number: "3.0",
        name: "Cloud Technology and Services",
        percentage: "34%",
      },
      {
        number: "4.0",
        name: "Billing, Pricing, and Support",
        percentage: "12%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official AWS Certified Cloud Practitioner CLF-C02 exam guide:",
    more_domain_info_link:
      "https://d1.awsstatic.com/training-and-certification/docs-cloud-practitioner/AWS-Certified-Cloud-Practitioner_Exam-Guide.pdf",
    study_resources: [
      {
        provider: "Jason Dion",
        provider_link_available: true,
        provider_link: "https://www.diontraining.com/courses/aws-clf",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link:
          "https://www.pluralsight.com/courses/aws-cloud-practitioner-exam-prep",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Andrew Brown",
        provider_link_available: true,
        provider_link: "https://www.youtube.com/watch?v=NhDYbskXRgc",
        free: true,
        video_content: true,
        practice_exams: false,
      },
      {
        provider: "Exam Pro",
        provider_link_available: true,
        provider_link: "https://www.exampro.co/aws-choose-an-exam",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "W3Schools",
        provider_link_available: true,
        provider_link: "https://www.w3schools.com/aws/aws_quiz.php",
        free: true,
        video_content: false,
        practice_exams: true,
      },
    ],
  },
};

export default aws_ccp;
