import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { deletePastExam } from "../../../store/reducers/appSlice";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PastExamTile = ({ exam }) => {
  const pastExams = useSelector((store) => store.app.pastExams);
  const pastExamIndex = pastExams.findIndex(
    (e) => e.settings.id === exam.settings.id
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteExam = () => {
    dispatch(deletePastExam(exam.settings.id));
  };

  const viewExamSummary = () => {
    navigate(`/exam/${exam.settings.certId}`, {
      state: { pastExamIndex: pastExamIndex, viewPastExam: true },
    });
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 225,
        height: 175,
        backgroundColor: "#22272b",
        color: "white",
        borderRadius: 3,
        justifyContent: "space-between",
        alignItems: "space-between",
        margin: 1,
        ":hover": {
          cursor: "pointer",
          boxShadow: 20,
        },
      }}
    >
      <CardContent sx={{ textAlign: "center" }}>
        <Typography gutterBottom variant="body1">
          {exam.settings.cert}
        </Typography>

        <Typography gutterBottom variant="body1">
          Score: {exam.settings.numCorrect || 0} / {exam.settings.numQuestions}{" "}
          -{" "}
          {(
            (exam.settings.numCorrect || 0 / exam.settings.numQuestions) * 100
          ).toFixed(2)}
          %
        </Typography>

        <Typography gutterBottom variant="body1">
          Time Remaining: {(exam.settings.timeRemaining / 60).toFixed(2)}
        </Typography>

        <Box>
          <Button onClick={viewExamSummary} variant="contained" color="success">
            View
          </Button>

          <Button
            onClick={deleteExam}
            variant="contained"
            color="error"
            sx={{ marginLeft: 1 }}
          >
            Delete
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PastExamTile;
