const CURRENT_VERSION = "1.0.0";

export const localStorageMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  const savedVersion = localStorage.getItem("appVersion");
  if (savedVersion !== CURRENT_VERSION) {
    localStorage.setItem("appVersion", CURRENT_VERSION);
    localStorage.removeItem("appState");
    store.dispatch({ type: "app/resetState" });
  }

  const state = store.getState();
  localStorage.setItem("appState", JSON.stringify(state.app));
  localStorage.setItem("appVersion", CURRENT_VERSION);

  return result;
};
