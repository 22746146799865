import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { mutateQuestion } from "../../../../store/reducers/appSlice";

const StandardSingleAnswerQuestionComponent = ({
  currentQuestionIndex,
  question,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const dispatch = useDispatch();

  const answerSingleAnswerQuestion = (answer) => {
    question.USER_ANSWERS = [answer];
    question.SKIPPED = false;
    question.ANSWERED = true;

    dispatch(
      mutateQuestion({
        index: currentQuestionIndex,
        question: question,
      })
    );
  };

  useEffect(() => {
    if (
      question.hasOwnProperty("USER_ANSWERS") &&
      question.USER_ANSWERS.length > 0
    ) {
      setSelectedAnswer(question.USER_ANSWERS[0].ANSWER_TEXT);
    }
  }, [question]);

  const handleRadioChange = (answer) => {
    setSelectedAnswer(answer.ANSWER_TEXT);
    answerSingleAnswerQuestion(answer);
  };

  return (
    <Box>
      <FormControl>
        <RadioGroup
          value={selectedAnswer}
          onChange={(e) => {
            const selectedAnswer = question.ANSWER_OPTIONS.find(
              (option) => option.ANSWER_TEXT === e.target.value
            );
            handleRadioChange(selectedAnswer);
          }}
        >
          {question?.ANSWER_OPTIONS.map((answer) => (
            <FormControlLabel
              key={answer.ANSWER_TEXT}
              value={answer.ANSWER_TEXT}
              control={<Radio />}
              label={answer.ANSWER_ID + ". " + answer.ANSWER_TEXT}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default StandardSingleAnswerQuestionComponent;
