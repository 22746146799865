import aws_ccp from "./certs/aws_ccp";
import aws_cda from "./certs/aws_cda";
import aws_csaa from "./certs/aws_csaa";

const AWS = {
  id: 2,
  name: "AWS",
  certifications: [{ ...aws_ccp }, { ...aws_cda }, { ...aws_csaa }],
};

export default AWS;
