const aws_cda = {
  id: "aws-cda",
  name: "AWS Certified Developer Associate",
  description: "AWS Certified Developer Associate",
  icon: require("../../../../assets/aws-cda.png"),
  questions: 65,
  time: 7800,
  exam_id: "DVA-C01",
  topics: [
    "Deployment-Management",
    "Infrastructure-as-Code",
    "CloudFormation",
    "Elastic-Beanstalk",
    "AWS-SDKs",
    "AWS-CLI",
    "CodePipeline",
    "CodeBuild",
    "CodeDeploy",
    "Identity-and-Access-Management",
    "Key-Management-Service",
    "Secrets-Manager",
    "Encryption",
    "API-Gateway-Security",
    "Cognito",
    "App-Config-Security",
    "Lambda",
    "API-Gateway",
    "DynamoDB",
    "S3",
    "SQS",
    "SNS",
    "Kinesis",
    "Step-Functions",
    "Aurora",
    "RDS",
    "ElastiCache",
    "Elastic-Transcoder",
    "CloudFront",
    "Serverless-Applications",
    "Containerization",
    "Microservices-Architecture",
    "Application-Optimization",
    "CloudWatch",
    "X-Ray",
    "CloudTrail",
    "Logging",
    "Application-Performance-Monitoring",
    "AWS-Config",
    "AWS-Trusted-Advisor",
    "Regions-and-AZs",
    "Networking",
    "VPC",
    "Elastic-Load-Balancing",
    "Auto-Scaling",
    "Route-53",
    "RDS",
    "CI/CD",
    "Testing-and-Debugging",
    "Version-Control",
    "Code-Review-Processes",
    "Agile-Development",
    "Infrastructure-as-Code",
  ],

  info: {
    exam_details: [
      { name: "Exam ID", value: "DVA-C02" },
      { name: "Number of Questions", value: "65 Questions" },
      {
        name: "Question Types",
        value: "Multiple Choice / Multiple Response",
      },
      { name: "Length of Test", value: "130 Minutes" },
      { name: "Passing Score", value: "720 (on a scale of 100-1000)" },
      { name: "Cost of Exam", value: "$150.00" },
      { name: "Validity", value: "3 years" },
      {
        name: "Testing Options",
        value: "Pearson VUE testing center or online proctored exam",
      },
    ],
    domains: [
      {
        number: "1.0",
        name: "Development with AWS Services",
        percentage: "32%",
      },
      { number: "2.0", name: "Security", percentage: "26%" },
      {
        number: "3.0",
        name: "Deployment",
        percentage: "24%",
      },
      {
        number: "4.0",
        name: "Troubleshooting and Optimization",
        percentage: "18%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official AWS Certified Developer Associate exam guide:",
    more_domain_info_link:
      "https://d1.awsstatic.com/training-and-certification/docs-dev-associate/AWS-Certified-Developer-Associate_Exam-Guide.pdf",
    study_resources: [
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link:
          "https://www.pluralsight.com/courses/aws-certified-developer---associate-dva-c01",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Andrew Brown - FreeCodeCamp",
        provider_link_available: true,
        provider_link: "https://www.youtube.com/watch?v=RrKRN9zRBWs",
        free: true,
        video_content: true,
        practice_exams: false,
      },
      {
        provider: "Exam Pro",
        provider_link_available: true,
        provider_link: "https://www.exampro.co/aws-choose-an-exam",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "ExamTopics",
        provider_link_available: true,
        provider_link:
          "https://www.examtopics.com/exams/amazon/aws-certified-developer-associate-dva-c02/",
        free: true,
        video_content: false,
        practice_exams: true,
      },
    ],
  },
};

export default aws_cda;
