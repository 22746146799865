const aws_csaa = {
  id: "aws-csaa",
  name: "AWS Certified Solutions Architect Associate",
  description: "AWS Certified Solutions Architect Associate",
  icon: require("../../../../assets/aws-csaa.png"),
  questions: 65,
  time: 7800,
  exam_id: "SAA-C03",
  topics: [
    "High-Availability",
    "Fault-Tolerance",
    "Multi-AZ-Deployments",
    "Elasticity",
    "Scalability",
    "Elastic-Load-Balancing (ELB)",
    "Auto-Scaling",
    "Disaster-Recovery",
    "EC2",
    "Lambda",
    "ECS-and-EKS",
    "Elastic-Beanstalk",
    "S3",
    "EFS",
    "FSx",
    "Glacier",
    "RDS",
    "DynamoDB",
    "Aurora",
    "Redshift",
    "VPC",
    "Route-53",
    "CloudFront",
    "Direct-Connect",
    "API-Gateway",
    "IAM (Identity-and-Access-Management)",
    "Key-Management-Service (KMS)",
    "AWS-Organizations",
    "AWS-Shield",
    "AWS-WAF",
    "Security-Groups-and-NACLs",
    "Data-Encryption (at-rest-and-in-transit)",
    "CloudTrail",
    "Config",
    "Cost-Management",
    "AWS-Pricing-Models",
    "On-Demand",
    "Reserved",
    "Spot-Instances",
    "Savings-Plans",
    "Billing-and-Tagging",
    "Right-Sizing",
    "AWS-Migration-Hub",
    "Server-Migration-Service (SMS)",
    "Database-Migration-Service (DMS)",
    "Snowball",
    "CloudEndure",
    "Infrastructure-as-Code (IaC)",
    "CloudFormation",
    "Terraform",
    "CI/CD-Pipelines",
    "CodePipeline",
    "CodeBuild",
    "CodeDeploy",
    "Deployment-Best-Practices",
    "CloudWatch",
    "CloudTrail",
    "AWS-Config",
    "X-Ray",
    "VPC-Flow-Logs",
    "GuardDuty",
    "VPC",
    "Subnets",
    "NAT-Gateway",
    "VPN",
    "Transit-Gateway",
    "Direct-Connect",
    "Route-53",
    "Elastic-Load-Balancing (ELB)",
    "Global-Accelerator",
    "CloudFront",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "SAA-C03" },
      { name: "Number of Questions", value: "65 Questions" },
      {
        name: "Question Types",
        value: "Multiple Choice / Multiple Response",
      },
      { name: "Length of Test", value: "130 Minutes" },
      { name: "Passing Score", value: "720 (on a scale of 100-1000)" },
      { name: "Cost of Exam", value: "$150.00" },
      { name: "Validity", value: "3 years" },
      {
        name: "Testing Options",
        value: "Pearson VUE testing center or online proctored exam",
      },
    ],
    domains: [
      {
        number: "1.0",
        name: "Design Secure Architectures",
        percentage: "30%",
      },
      {
        number: "2.0",
        name: " Design Resilient Architectures",
        percentage: "26%",
      },
      {
        number: "3.0",
        name: " Design High-Performing Architectures ",
        percentage: "24%",
      },
      {
        number: "4.0",
        name: "Design Cost-Optimized Architectures",
        percentage: "20%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official AWS Certified Solutions Architect Associate exam guide:",
    more_domain_info_link:
      "https://d1.awsstatic.com/training-and-certification/docs-sa-assoc/AWS-Certified-Solutions-Architect-Associate_Exam-Guide.pdf",
    study_resources: [
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link:
          "https://www.pluralsight.com/paths/aws-certified-solutions-architect-associate-saa-c03",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Andrew Brown - FreeCodeCamp",
        provider_link_available: true,
        provider_link: "https://www.youtube.com/watch?v=c3Cn4xYfxJY",
        free: true,
        video_content: true,
        practice_exams: false,
      },
      {
        provider: "Exam Pro",
        provider_link_available: true,
        provider_link: "https://www.exampro.co/aws-choose-an-exam",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "ExamTopics",
        provider_link_available: true,
        provider_link:
          "https://www.examtopics.com/exams/amazon/aws-certified-solutions-architect-associate-saa-c03/",
        free: true,
        video_content: false,
        practice_exams: true,
      },
    ],
  },
};

export default aws_csaa;
