import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const About = () => {
  return (
    <span style={{ minHeight: "87vh" }}>
      <Card
        sx={{
          maxWidth: 800,
          backgroundColor: "#1d2025",
          color: "white",
          borderRadius: 3,
          marginTop: 3,
        }}
      >
        <CardContent>
          <Typography
            variant="h4"
            component="div"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            About Us
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginBottom: 2 }}>
            Welcome to CertNova, your go-to platform for high-quality
            certification exam preparation. At CertNova, we are dedicated to
            providing you with the best FREE resources and practice exams to
            help you achieve your certification goals. Our mission is to empower
            individuals to excel in their careers by offering comprehensive,
            accessible, and reliable exam preparation materials.
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginBottom: 2 }}>
            At CertNova, we understand the importance of thorough preparation
            for certification exams. That's why we've designed our platform to
            offer a wide range of practice exams that cover various
            certification topics. Whether you're preparing for IT
            certifications, project management certifications, or any other
            professional exams, CertNova has got you covered. Our practice exams
            are meticulously crafted to mirror the format and content of the
            actual exams, ensuring that you get the most realistic and effective
            preparation possible.
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginBottom: 2 }}>
            Our website is ad-supported, which means that all our resources are
            available to you for free, provided you disable any ad blockers. We
            believe in making education accessible to everyone, and our
            ad-supported model allows us to offer top-notch exam preparation
            without any cost to you. If you have any questions or need
            assistance, our support team is always here to help. Feel free to
            reach out to us at support@web-nova.com. Thank you for choosing
            CertNova, and we look forward to supporting you on your
            certification journey.
          </Typography>
        </CardContent>
      </Card>
    </span>
  );
};

export default About;
