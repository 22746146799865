import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

export const Footer = () => {
  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: "#22272b",
        }}
      >
        <Typography variant="body2" color="inherit">
          &copy; 2024 CertNova. All rights reserved.
        </Typography>
        <span
          style={{
            display: "flex",
            gap: 20,
          }}
        >
          <Link to="/about" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="body1">About Us</Typography>
          </Link>
          {/* <Link
              to="/contact"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Typography variant="body2">Contact Us</Typography>
            </Link> */}
          <Link
            to="https://www.certnova.com/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Typography variant="body1">Privacy Policy</Typography>
          </Link>

          <Link
            to="https://www.certnova.com/cookie-policy.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Typography variant="body1">Cookie Policy</Typography>
          </Link>
        </span>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
