import { useState } from "react";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import ExamProvider from "./components/ExamProvider";
import PausedExamTile from "./components/PausedExamTile";
import PastExamTile from "./components/PastExamTile";
import PROVIDERS from "../../data/providers/providers";

const Main = () => {
  const pastExams = useSelector((store) => store?.app?.pastExams);
  const pausedExams = useSelector((store) => store?.app?.pausedExams);

  const [showMorePaused, setShowMorePaused] = useState(false);
  const [showMorePast, setShowMorePast] = useState(false);
  const [hidePastExams, setHidePastExams] = useState(false);

  const toggleShowMorePaused = () => {
    setShowMorePaused(!showMorePaused);
  };

  const toggleShowMorePast = () => {
    setShowMorePast(!showMorePast);
  };

  const toggleHidePastExams = () => {
    setHidePastExams(!hidePastExams);
  };

  return (
    <span style={{ minHeight: "87vh" }}>
      <Card
        sx={{
          maxWidth: 800,
          backgroundColor: "#1d2025",
          color: "white",
          borderRadius: 3,
          marginTop: 3,
        }}
      >
        <CardContent>
          <Typography
            sx={{ textAlign: "center" }}
            gutterBottom
            variant="h4"
            component="div"
          >
            Welcome to CertNova
          </Typography>
          <br />
          <Typography variant="body2" color="white">
            Welcome to CertNova! We're excited to help you on your journey to
            certification success. At CertNova, you'll find a wide range of free
            resources to assist you in your exam preparation. Whether you're
            studying for CompTIA, Amazon Web Services (AWS), or any other
            professional certification, our comprehensive practice exams and
            study materials are here to support you every step of the way. Dive
            into our extensive collection of test questions and get ready to ace
            your exams with confidence!
          </Typography>
          <br />
          {pastExams && pastExams.length > 0 && (
            <span>
              {!hidePastExams && (
                <Typography
                  sx={{ textAlign: "center" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Past Exams
                </Typography>
              )}
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                container
                spacing={1}
              >
                {!hidePastExams &&
                  pastExams.map((exam, index) => {
                    if (!showMorePast && index > 2) return null;
                    return (
                      <PastExamTile
                        key={exam.settings.id}
                        exam={exam}
                        hidePastExams={hidePastExams}
                        setHidePastExams={setHidePastExams}
                      />
                    );
                  })}
              </Grid>
              <br />
              {pastExams && pastExams.length > 0 && (
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={toggleHidePastExams}
                    variant="outlined"
                    sx={{ color: "white" }}
                  >
                    {hidePastExams ? "Show" : "Hide"}
                  </Button>

                  {pastExams.length > 3 && (
                    <Button
                      onClick={toggleShowMorePast}
                      variant="outlined"
                      sx={{ color: "white" }}
                    >
                      {showMorePast ? "Show Less" : "Show More"}
                    </Button>
                  )}
                </span>
              )}
              <hr />
            </span>
          )}

          {pausedExams && pausedExams.length > 0 && (
            <span>
              <Typography
                sx={{ textAlign: "center" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                Paused Exams:
              </Typography>
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                container
                spacing={1}
              >
                {pausedExams.map((exam, index) => {
                  if (!showMorePaused && index > 2) return null;
                  return <PausedExamTile key={exam.settings.id} exam={exam} />;
                })}
                <br />
              </Grid>
              <br />
              {pausedExams && pausedExams.length > 3 && (
                <Button
                  onClick={toggleShowMorePaused}
                  variant="outlined"
                  sx={{ color: "white" }}
                >
                  {showMorePaused ? "Show Less" : "Show More"}
                </Button>
              )}
              <hr />
            </span>
          )}
          <Typography
            sx={{ textAlign: "center" }}
            gutterBottom
            variant="h5"
            component="div"
          >
            Select a practice exam below
          </Typography>

          {PROVIDERS.map((provider) => (
            <ExamProvider key={provider.id} provider={provider} />
          ))}
        </CardContent>
      </Card>
    </span>
  );
};

export default Main;
