import { createSlice } from "@reduxjs/toolkit";

const CURRENT_VERSION = "1.0.0";

const loadStateFromLocalStorage = () => {
  try {
    const savedVersion = localStorage.getItem("appVersion");
    const serializedState = localStorage.getItem("appState");

    if (savedVersion !== CURRENT_VERSION || serializedState === null) {
      localStorage.setItem("appVersion", CURRENT_VERSION);
      return {
        provider: {},
        cert: {},
        acceptNotice: false,
        currentExam: {
          settings: {},
          questions: [],
        },
        pastExams: [],
        pausedExams: [],
      };
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return {
      provider: {},
      cert: {},
      acceptNotice: false,
      currentExam: {
        settings: {},
        questions: [],
      },
      pastExams: [],
      pausedExams: [],
    };
  }
};

const initialState = loadStateFromLocalStorage();

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setProvider: (state, provider) => {
      state.provider = provider.payload;
    },
    setCert: (state, cert) => {
      state.cert = cert.payload;
    },
    acceptNotice: (state) => {
      state.acceptNotice = true;
    },
    setExamSettings: (state, settings) => {
      state.exam.settings = settings.payload;
    },
    setExamQuestions: (state, questions) => {
      state.currentExam.questions = questions.payload;
    },
    startNewExam: (state, exam) => {
      if (state.currentExam?.settings?.id) {
        state.pastExams.push(state.currentExam);
      }
      state.currentExam = exam.payload;
    },
    pauseCurrentExam: (state, exam) => {
      state.pausedExams.unshift(exam.payload);
      state.currentExam = {
        settings: {},
        questions: [],
      };
    },

    mutateQuestion: (state, info) => {
      state.currentExam.questions[info.payload.index] = info.payload.question;
    },
    updateCurrentExam: (state, exam) => {
      state.currentExam = exam.payload;
    },
    deletePausedExam: (state, index) => {
      state.pausedExams.splice(index.payload, 1);
    },
    resumePausedExam: (state, id) => {
      if (state.currentExam?.settings?.id) {
        state.pausedExams.push(state.currentExam);
      }
      const index = state.pausedExams.findIndex(
        (exam) => exam.settings.id === id.payload
      );
      state.currentExam = state.pausedExams[index];
      state.pausedExams.splice(index, 1);
    },
    finishCurrentExam: (state) => {
      state.pastExams.unshift({ ...state.currentExam });
      state.currentExam = {
        settings: {},
        questions: [],
      };
    },
    deletePastExam: (state, index) => {
      state.pastExams.splice(index.payload, 1);
    },
    resetState: (state) => {
      return initialState;
    },
  },
});

export const {
  setProvider,
  setCert,
  acceptNotice,
  setExamSettings,
  setExamQuestions,
  startNewExam,
  pauseCurrentExam,
  mutateQuestion,
  deletePausedExam,
  resumePausedExam,
  updateCurrentExam,
  finishCurrentExam,
  deletePastExam,
} = appSlice.actions;

export default appSlice.reducer;
