const security_plus = {
  id: "security-plus",
  name: "CompTIA Security+",
  description: "Comptia Security+",
  icon: require("../../../../assets/comptia-securityplus.png"),
  questions: 65,
  time: 5400,
  exam_id: "SY0-601",
  topics: [
    "Social-Engineering",
    "Phishing-Variants",
    "Malware-Types",
    "Privilege-Escalation",
    "Insider-Threats",
    "Advanced-Persistent-Threat",
    "DoS-DDoS",
    "Injection-Attacks",
    "Cross-Site-Scripting",
    "Buffer-Overflows",
    "Vulnerability-Scanning",
    "Penetration-Testing",
    "Secure-Network-Design",
    "Network-Segmentation",
    "Demilitarized-Zone",
    "Wireless-Security",
    "Cloud-Security",
    "Virtualization-Security",
    "Application-Security",
    "Embedded-Systems-Security",
    "Physical-Security-Controls",
    "Security-Frameworks",
    "Regulatory-Compliance",
    "Identity-Access-Management",
    "Authentication-Methods",
    "Authorization",
    "Multifactor-Authentication",
    "Public-Key-Infrastructure",
    "Wireless-Security-Settings",
    "Mobile-Device-Security",
    "Endpoint-Security",
    "Data-Loss-Prevention",
    "Firewalls",
    "Intrusion-Detection",
    "Intrusion-Prevention",
    "Security-Information-and-Event-Management",
    "Email-Security",
    "Cloud-Security-Solutions",
    "Incident-Response-Process",
    "Digital-Forensics",
    "Data-Collection",
    "Recovery-Sites",
    "Backup-Types",
    "Disaster-Recovery",
    "Business-Continuity",
    "Logging-and-Monitoring",
    "SIEM-Analysis",
    "Incident-Analysis",
    "Change-Management",
    "Risk-Management",
    "Risk-Assessment",
    "Risk-Mitigation",
    "Security-Controls",
    "Security-Policies",
    "Data-Protection",
    "Privacy-Compliance",
    "Auditing",
    "Security-Awareness-Training",
    "Third-Party-Risk",
    "Supply-Chain-Risk",
    "Security-Assessment",
    "Business-Impact-Analysis",
    "Compliance-Regulations",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "SY0-701" },
      { name: "Number of Questions", value: "Maximum of 90" },
      {
        name: "Question Types",
        value: "Multiple Choice / Performance Based",
      },
      { name: "Length of Test", value: "90 Minutes" },
      {
        name: "Recommended Experience",
        value:
          "Minimum of 9–12 months of hands-on experience working in a junior network administrator/ network support technician job role",
      },
      { name: "Passing Score", value: "720 (on a scale of 100-900)" },
      { name: "Cost of Exam", value: "$369.00" },
      { name: "Validity", value: "3 years" },
    ],
    domains: [
      {
        number: "1.0",
        name: "Networking Fundamentals",
        percentage: "24%",
      },
      {
        number: "2.0",
        name: "Network Implementations",
        percentage: "19%",
      },
      {
        number: "3.0",
        name: "Network Operations",
        percentage: "16%",
      },
      {
        number: "4.0",
        name: "Network Security",
        percentage: "19%",
      },
      {
        number: "5.0",
        name: "Network Troubleshooting",
        percentage: "22%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official CompTIA Network+ N10-008 exam objectives:",
    more_domain_info_link:
      "https://partners.comptia.org/docs/default-source/resources/comptia-network-n10-008-exam-objectives-(2-0)",
    study_resources: [
      {
        provider: "Mike Myers",
        provider_link_available: true,
        provider_link: "https://www.totalsem.com/network-plus-certification/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Professor Messer",
        provider_link_available: true,
        provider_link:
          "https://www.professormesser.com/network-plus/n10-008/n10-008-video/n10-008-training-course/",
        free: true,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Jason Dion",
        provider_link_available: true,
        provider_link: "https://www.diontraining.com/courses/comptia-network",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link:
          "https://www.pluralsight.com/paths/comptia-network-n10-008",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PowerCert Animated Videos",
        provider_link_available: true,
        provider_link: "https://www.youtube.com/watch?v=vrh0epPAC5w",
        free: true,
        video_content: true,
        practice_exams: false,
      },
    ],
  },
};

export default security_plus;
