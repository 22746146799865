import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/appSlice";
import { localStorageMiddleware } from "./middleware/localStorageMiddleware";

export default configureStore({
  reducer: {
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});
