import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const CertInformation = ({ info }) => {
  return (
    <Box>
      <TableContainer
        sx={{
          borderRadius: 3,
          marginBottom: 2,
        }}
        component={Paper}
      >
        <Table aria-label="exam-details">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                EXAM DETAILS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {info.exam_details &&
              info.exam_details.map((item) => (
                <TableRow key={item.name}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="center">{item.value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        sx={{ borderRadius: 3, marginBottom: 2 }}
        component={Paper}
      >
        <Table aria-label="exam-objectives">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                EXAM OBJECTIVES (DOMAINS)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component={"th"} scope={"row"}>
                #
              </TableCell>
              <TableCell component={"th"} scope={"row"}>
                DOMAIN
              </TableCell>
              <TableCell component={"th"} scope={"row"}>
                PERCENTAGE OF EXAM
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {info.domains.map((objective) => (
              <TableRow key={objective.number}>
                <TableCell component={"th"} scope={"row"}>
                  {objective.number}
                </TableCell>
                <TableCell component={"th"} scope={"row"}>
                  {objective.name}
                </TableCell>
                <TableCell component={"th"} scope={"row"}>
                  {objective.percentage}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {info.more_domain_info_available && (
        <Typography sx={{ textAlign: "center", fontSize: 10 }} variant="body2">
          {info.more_domain_info_message}{" "}
          <a
            style={{ textDecoration: "none", color: "lightgray" }}
            href={info.more_domain_info_link}
            target="_blank"
            rel="noreferrer"
          >
            {info.more_domain_info_link}
          </a>
        </Typography>
      )}
      <br />
      <TableContainer sx={{ borderRadius: 3 }} component={Paper}>
        <Table aria-label="study-resources">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={4}>
                STUDY RESOURCES
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component={"th"} scope={"row"}>
                PROVIDER
              </TableCell>
              <TableCell component={"th"} scope={"row"}>
                VIDEO CONTENT
              </TableCell>
              <TableCell component={"th"} scope={"row"}>
                PRACTICE EXAMS
              </TableCell>
              <TableCell component={"th"} scope={"row"}>
                FREE
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {info.study_resources.map((resource) => (
              <TableRow key={resource.provider}>
                <TableCell component={"th"} scope={"row"}>
                  {resource.provider_link_available ? (
                    <a
                      href={resource.provider_link}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {resource.provider}{" "}
                      <OpenInNewIcon sx={{ fontSize: 12 }} />
                    </a>
                  ) : (
                    resource.provider
                  )}
                </TableCell>

                <TableCell component={"th"} scope={"row"}>
                  {resource.video_content ? <CheckIcon /> : <CloseIcon />}
                </TableCell>
                <TableCell component={"th"} scope={"row"}>
                  {resource.practice_exams ? <CheckIcon /> : <CloseIcon />}
                </TableCell>
                <TableCell component={"th"} scope={"row"}>
                  {resource.free ? <CheckIcon /> : <CloseIcon />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CertInformation;
