import a_plus_core_1 from "./certs/a_plus_core_1";
import a_plus_core_2 from "./certs/a_plus_core_2";
import network_plus from "./certs/network_plus";
import security_plus from "./certs/security_plus";

const COMPITA = {
  id: 1,
  name: "CompTIA",
  certifications: [
    { ...a_plus_core_1 },
    { ...a_plus_core_2 },
    { ...network_plus },
    { ...security_plus },
  ],
};

export default COMPITA;
