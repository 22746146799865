import { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import axios from "axios";
import ReportQuestionDialog from "./ReportQuestionDialog";
import ExamSummary from "./ExamSummary";
import QuestionGrid from "./QuestionGrid";
import StandardSingleAnswerQuestionComponent from "./questions/StandardSingleAnswerQuestionComponent";
import StandardMultipleAnswerQuestionComponent from "./questions/StandardMultipleAnswerQuestionComponent";
import QuestionSummaryItem from "./questions/QuestionSummaryItem";
import {
  mutateQuestion,
  pauseCurrentExam,
  finishCurrentExam,
  updateCurrentExam,
} from "../../../store/reducers/appSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const StandardExam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentExam = JSON.parse(
    JSON.stringify(useSelector((store) => store.app.currentExam))
  );
  const cert = useSelector((store) => store.app.cert);
  const location = useLocation();
  const pastExamIndex = location.state?.pastExamIndex || 0;
  const viewPastExam = location.state?.viewPastExam || false;

  const { timed, instantFeedback } = currentExam.settings;
  const questions = currentExam.questions;

  const [examFinished, setExamFinished] = useState(
    pastExamIndex >= 0 && viewPastExam ? true : false
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(
    currentExam.settings.timeRemaining
  );
  const [minutes, setMinutes] = useState(
    Math.floor(currentExam.settings.timeRemaining / 60)
  );
  const [seconds, setSeconds] = useState("00");
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [reportResponseMessage, setReportResponseMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPauseAlert, setShowPauseAlert] = useState(false);
  const [currQuestionAnswered, setCurrQuestionAnswered] = useState(null);
  const [reportReason, setReportReason] = useState("");

  const handleClickOpenReportDialog = () => {
    setReportResponseMessage("");
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };

  const handleReportReasonChange = (value) => {
    setReportReason(value);
  };

  const handleSubmitReport = async () => {
    const URL =
      `https://0sxyh5cksj.execute-api.us-east-1.amazonaws.com/question/report?id=${questions[currentQuestionIndex].id}&cert=${cert}&report_reason=${reportReason}&ts=` +
      Date.now();

    let response = await axios.get(URL);

    if (response.data) {
      setReportResponseMessage(response.data.msg);
    }

    setTimeout(() => {
      setOpenReportDialog(false);
    }, 3000);
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      if (
        questions[currentQuestionIndex] &&
        !questions[currentQuestionIndex]?.hasOwnProperty("USER_ANSWERS")
      ) {
        questions[currentQuestionIndex].SKIPPED = true;
        questions[currentQuestionIndex].ANSWERED = false;
      }

      dispatch(
        mutateQuestion({
          index: currentQuestionIndex,
          question: questions[currentQuestionIndex],
        })
      );
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrQuestionAnswered(questions[currentQuestionIndex + 1].ANSWERED);
    }
  };

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setCurrQuestionAnswered(questions[currentQuestionIndex - 1].ANSWERED);
    }
  };

  const pressSubmitButton = () => {
    let allAnswered = true;
    allAnswered &&
      questions.forEach((question) => {
        if (question && !question.hasOwnProperty("USER_ANSWERS")) {
          allAnswered = false;
        }
      });
    if (!allAnswered) {
      setShowAlert(true);
      return;
    }

    finishExam();
  };

  const pressPauseButton = () => {
    console.log(currentExam);
    setShowPauseAlert(true);
  };

  const finishExam = () => {
    currentExam.settings.numCorrect = 0;

    questions.forEach((question, index) => {
      if (question && !question?.hasOwnProperty("USER_ANSWERS")) {
        question.USER_ANSWERS = [];
        question.SKIPPED = true;
      } else checkAnswer(question, index);
    });

    questions.forEach((question) => {
      if (question.CORRECT) {
        currentExam.settings.numCorrect += 1;
      }
    });

    currentExam.settings.finished = true;

    dispatch(updateCurrentExam(currentExam));
    dispatch(finishCurrentExam());
    setExamFinished(true);
  };

  const pauseExam = () => {
    timed
      ? (currentExam.settings.timeRemaining =
          parseInt(minutes) * 60 + parseInt(seconds))
      : (currentExam.settings.timeRemaining = null);
    currentExam.settings.paused = true;
    currentExam.settings.timestamp = Date.now();
    currentExam.settings.finished = false;
    currentExam.settings.currentQuestionIndex = currentQuestionIndex;
    currentExam.settings.cert = cert.name;
    currentExam.settings.certId = cert.id;

    setShowPauseAlert(false);
    navigate("/");
    dispatch(pauseCurrentExam(currentExam));
  };

  const checkAnswer = (question, index) => {
    if (isCorrect(question)) {
      setCurrQuestionAnswered(true);
      question.SKIPPED = false;
      question.CORRECT = true;
      question.ANSWERED = true;
    } else {
      setCurrQuestionAnswered(true);
      question.SKIPPED = false;
      question.CORRECT = false;
      question.ANSWERED = true;
    }

    dispatch(
      mutateQuestion({
        index: index,
        question: question,
      })
    );
  };

  const isCorrect = (question) => {
    let correct = false;
    correct = arraysEqual(question?.CORRECT_ANSWERS, question?.USER_ANSWERS);

    return correct;
  };

  const arraysEqual = (a1, a2) => {
    if (!Array.isArray(a1) || !Array.isArray(a2)) {
      return false;
    }

    let sortedA1 = [...a1].sort((a, b) => a?.ANSWER_ID - b?.ANSWER_ID);
    let sortedA2 = [...a2].sort((a, b) => a?.ANSWER_ID - b?.ANSWER_ID);

    return (
      sortedA1.length === sortedA2.length &&
      sortedA1.every((o, idx) => objectsEqual(o, sortedA2[idx]))
    );
  };

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const computeColor = (question) => {
    if (question.SKIPPED) return "#9e9e9e";
    if (isCorrect(question)) {
      return "#4caf50";
    } else {
      return "#f44336";
    }
  };

  const formatAndUpdateTimeRemaining = () => {
    const minutes = Math.floor(timeRemaining / 60);
    const zerofilledMinutes = ("0000" + minutes).slice(-2);
    const seconds = timeRemaining - minutes * 60;
    const zerofilledSeconds = ("0000" + seconds).slice(-2);

    if (timeRemaining > 0) {
      setTimeRemaining(timeRemaining - 1);
      setMinutes(zerofilledMinutes);
      setSeconds(zerofilledSeconds);
    } else {
      finishExam();
    }
  };

  useEffect(() => {
    if (timed && timeRemaining > -1 && examFinished === false) {
      const interval = setInterval(() => formatAndUpdateTimeRemaining(), 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining, examFinished]);

  return (
    <span style={{ minHeight: "87vh" }}>
      <Card
        sx={{
          maxWidth: 1200,
          backgroundColor: "#22272b",
          color: "white",
          borderRadius: 3,
          marginTop: 3,
          marginBottom: 50,
          marginRight: 2,
          marginLeft: 2,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent>
          <Typography sx={{ fontSize: 25, textAlign: "center" }} gutterBottom>
            {cert.name} Practice Exam
          </Typography>

          {currentExam?.settings?.id && (
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="warning"
                onClick={handleClickOpenReportDialog}
                sx={{
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Report Question
              </Button>
              <br />
            </span>
          )}

          <ReportQuestionDialog
            openReportDialog={openReportDialog}
            handleCloseReportDialog={handleCloseReportDialog}
            handleSubmitReport={handleSubmitReport}
            reportResponseMessage={reportResponseMessage}
            currentQuestionIndex={currentQuestionIndex}
            handleReportReasonChange={handleReportReasonChange}
          />

          <QuestionGrid
            questions={questions}
            currentQuestionIndex={currentQuestionIndex}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
          />

          <br />

          {timeRemaining >= -1 ? (
            <Typography
              sx={{ textAlign: "center" }}
              variant="body1"
              component="div"
            >
              Time Remaining: {minutes}:{seconds}
            </Typography>
          ) : null}

          <hr />
          <br />

          {!examFinished ? (
            <span>
              <span
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="body2" component="div">
                  Question {currentQuestionIndex + 1} of {questions.length}:
                </Typography>

                <span>
                  <Button
                    sx={{ color: "white !important", marginRight: 1 }}
                    onClick={pressPauseButton}
                    variant="contained"
                    color="warning"
                  >
                    Pause
                  </Button>

                  <Button
                    sx={{ color: "white !important" }}
                    onClick={pressSubmitButton}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </span>
              </span>
              {showAlert && (
                <span>
                  <br />
                  <Alert
                    severity="warning"
                    variant="standard"
                    action={
                      <Grid container>
                        <Button
                          onClick={finishExam}
                          size="small"
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={() => setShowAlert(false)}
                        >
                          No
                        </Button>
                      </Grid>
                    }
                  >
                    <Typography variant="body1" component="div">
                      "It looks like you've skipped some questions. Are you sure
                      you want to submit?"
                    </Typography>
                  </Alert>
                  <br />
                </span>
              )}

              {showPauseAlert && (
                <span>
                  <br />
                  <Alert
                    severity="info"
                    variant="standard"
                    action={
                      <Grid container>
                        <Button
                          onClick={pauseExam}
                          size="small"
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={() => setShowPauseAlert(false)}
                        >
                          No
                        </Button>
                      </Grid>
                    }
                  >
                    Are you sure you want to pause the exam?
                  </Alert>
                  <br />
                </span>
              )}

              <br />
              <Typography variant="body1" component="div">
                {questions[currentQuestionIndex]?.PROMPT}
              </Typography>
              <br />

              {questions[currentQuestionIndex]?.TYPE === "multiple_answer" ? (
                <Box>
                  <StandardMultipleAnswerQuestionComponent
                    currentQuestionIndex={currentQuestionIndex}
                    question={questions[currentQuestionIndex]}
                  />

                  <br />
                  {instantFeedback && currQuestionAnswered ? (
                    <QuestionSummaryItem
                      question={questions[currentQuestionIndex]}
                      computeColor={computeColor}
                    />
                  ) : null}
                </Box>
              ) : (
                <Box>
                  <StandardSingleAnswerQuestionComponent
                    currentQuestionIndex={currentQuestionIndex}
                    question={questions[currentQuestionIndex]}
                  />

                  <br />
                  {instantFeedback && currQuestionAnswered ? (
                    <QuestionSummaryItem
                      question={questions[currentQuestionIndex]}
                      computeColor={computeColor}
                    />
                  ) : null}
                </Box>
              )}

              <br />
              <br />

              {instantFeedback && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    checkAnswer(questions[currentQuestionIndex]);
                  }}
                >
                  Check Answer
                </Button>
              )}
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  disabled={currentQuestionIndex === 0}
                  color="warning"
                  variant="contained"
                  onClick={previousQuestion}
                  sx={{ color: "lightgray !important", marginRight: 1 }}
                >
                  Back
                </Button>
                <Button
                  sx={{ color: "white !important" }}
                  disabled={currentQuestionIndex === questions.length - 1}
                  onClick={nextQuestion}
                  color="success"
                  variant="contained"
                >
                  Next
                </Button>
              </div>
            </span>
          ) : (
            <ExamSummary
              questions={questions}
              computeColor={computeColor}
              handleClickOpenReportDialog={handleClickOpenReportDialog}
              pastExamIndex={pastExamIndex}
            />
          )}
        </CardContent>
      </Card>
    </span>
  );
};

export default StandardExam;
