import { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import axios from "axios";
import ReportQuestionDialog from "./ReportQuestionDialog";
import ExamSummary from "./ExamSummary";
import QuestionGrid from "./QuestionGrid";
import StandardSingleAnswerQuestionComponent from "./questions/StandardSingleAnswerQuestionComponent";
import StandardMultipleAnswerQuestionComponent from "./questions/StandardMultipleAnswerQuestionComponent";
import QuestionSummaryItem from "./questions/QuestionSummaryItem";

const cert = {
  id: "a-plus",
  name: "CompTIA A+",
  description: "Comptia A+",
  questions: 65,
  time: 5400,
  modules: ["220-1101", "220-1102"],
  topics: [
    "Mobile-Devices",
    "Laptop-Components",
    "Mobile-Connectivity",
    "Network-Types",
    "Networking-Hardware",
    "Network-Protocols",
    "Storage-Devices",
    "CPU-GPU",
    "Power-Supplies",
    "Expansion-Cards",
    "Cloud-Computing",
    "Client-Virtualization",
    "Hardware-Troubleshooting",
    "Network-Troubleshooting",
    "Operating-Systems",
    "OS-Installation",
    "System-Management",
    "Security-Measures",
    "Device-Security",
    "Data-Security",
    "Security-Policies",
    "OS-Troubleshooting",
    "Mobile-Troubleshooting",
    "Security-Troubleshooting",
    "Safety-Procedures",
    "Environmental-Impact",
    "Documentation",
    "Disaster-Recovery",
    "Remote-Access",
  ],
};

const questions = [
  {
    ID: "b8f5c6b5-0f6c-4b3e-9c7b-6d9f2f8c9d4e",
    PROMPT: "What is an example of a valid IPV4 Address? (Choose two)",
    ANSWER_OPTIONS: [
      { ANSWER_ID: 1, ANSWER_TEXT: "255.255.255.0" },
      { ANSWER_ID: 2, ANSWER_TEXT: "255.255.0" },
      { ANSWER_ID: 3, ANSWER_TEXT: "255..0.0.0" },
      { ANSWER_ID: 4, ANSWER_TEXT: "255.255.255.255" },
    ],
    CORRECT_ANSWERS: [
      { ANSWER_ID: 1, ANSWER_TEXT: "255.255.255.0" },
      { ANSWER_ID: 4, ANSWER_TEXT: "255.255.255.255" },
    ],

    CERT: "a-plus",
    TOPIC: "Ip-Configuration",
    EXAM_ID: "220-1102",
    DIFFICULTY: "easy",
    ANSWER_EXPLANATION:
      "The default subnet mask for a Class C IP address is 255.255.255.0, which allows for 256 IP addresses within the subnet.",
    TYPE: "multiple_answer",
    VERSION: "1.0.0",
  },
  {
    ID: "b8f5c6b5-0f6c-4b3e-9c7b-6d9f2f8c9d41",
    PROMPT: "What is an example of a valid boobs Address? (Choose two)",
    ANSWER_OPTIONS: [
      { ANSWER_ID: 1, ANSWER_TEXT: "right ansur" },
      { ANSWER_ID: 2, ANSWER_TEXT: "wroing answer" },
      { ANSWER_ID: 3, ANSWER_TEXT: "right anzur" },
      { ANSWER_ID: 4, ANSWER_TEXT: "wrong anzur" },
    ],
    CORRECT_ANSWERS: [
      { ANSWER_ID: 1, ANSWER_TEXT: "right ansur" },
      { ANSWER_ID: 3, ANSWER_TEXT: "right anzur" },
    ],

    CERT: "a-plus",
    TOPIC: "Ip-Configuration",
    EXAM_ID: "220-1102",
    DIFFICULTY: "easy",
    ANSWER_EXPLANATION:
      "The default subnet mask for a Class C IP address is 255.255.255.0, which allows for 256 IP addresses within the subnet.",
    TYPE: "multiple_answer",
    VERSION: "1.0.0",
  },
  {
    ID: "b8f5c6b5-0f6c-4b3e-9c7b-6d9f2f119d4e",
    PROMPT: "What is an example of a valid IPV4 Address?",
    ANSWER_OPTIONS: [
      { ANSWER_ID: 1, ANSWER_TEXT: "255.255.255.1" },
      { ANSWER_ID: 2, ANSWER_TEXT: "255.255.00" },
      { ANSWER_ID: 3, ANSWER_TEXT: "255..0.00.0" },
      { ANSWER_ID: 4, ANSWER_TEXT: "255.255.2550.255.255" },
    ],
    CORRECT_ANSWERS: [{ ANSWER_ID: 1, ANSWER_TEXT: "255.255.255.1" }],
    CERT: "a-plus",
    TOPIC: "Ip-Configuration",
    EXAM_ID: "220-1102",
    DIFFICULTY: "easy",
    ANSWER_EXPLANATION:
      "The default subnet mask for a Class C IP address is 255.255.255.0, which allows for 256 IP addresses within the subnet.",
    TYPE: "multiple_choice",
    VERSION: "1.0.0",
  },
  {
    ID: "b8f5c6b5-0f6c-4b3e-9c7b-6d9f2f999d4e",
    PROMPT: "What is an example of a invalid IPV4 Address?",
    ANSWER_OPTIONS: [
      { ANSWER_ID: 1, ANSWER_TEXT: "255.255.255.0" },
      { ANSWER_ID: 2, ANSWER_TEXT: "255.255.0" },
      { ANSWER_ID: 3, ANSWER_TEXT: "255.0.0.0" },
      { ANSWER_ID: 4, ANSWER_TEXT: "255.255.255.255" },
    ],
    CORRECT_ANSWERS: [{ ANSWER_ID: 2, ANSWER_TEXT: "255.255.0" }],
    CERT: "a-plus",
    TOPIC: "Ip-Configuration",
    EXAM_ID: "220-1102",
    DIFFICULTY: "easy",
    ANSWER_EXPLANATION:
      "The default subnet mask for a Class C IP address is 255.255.255.0, which allows for 256 IP addresses within the subnet.",
    TYPE: "multiple_choice",
    VERSION: "1.0.0",
  },
  {
    ID: "b8f5c6b5-0f6c-4b3e-9c7b-6d9f2f719d4e",
    PROMPT: "What is an example of a invalid IPV4 Address? (Choose two)",
    ANSWER_OPTIONS: [
      { ANSWER_ID: 1, ANSWER_TEXT: "255.255.255.0" },
      { ANSWER_ID: 2, ANSWER_TEXT: "255.255.0" },
      { ANSWER_ID: 3, ANSWER_TEXT: "255..0.0.0" },
      { ANSWER_ID: 4, ANSWER_TEXT: "255.255.255.255" },
    ],
    CORRECT_ANSWERS: [
      { ANSWER_ID: 2, ANSWER_TEXT: "255.255.0" },
      { ANSWER_ID: 3, ANSWER_TEXT: "255..0.0.0" },
    ],

    CERT: "a-plus",
    TOPIC: "Ip-Configuration",
    EXAM_ID: "220-1102",
    DIFFICULTY: "easy",
    ANSWER_EXPLANATION:
      "The default subnet mask for a Class C IP address is 255.255.255.0, which allows for 256 IP addresses within the subnet.",
    TYPE: "multiple_answer",
    VERSION: "1.0.0",
  },
];

const timed = true;
const instantFeedback = false;

const TestComponent = () => {
  const [examFinished, setExamFinished] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(cert.time);
  const [minutes, setMinutes] = useState("Calculating");
  const [seconds, setSeconds] = useState("00:00");
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportResponseMessage, setReportResponseMessage] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [currQuestionAnswered, setCurrQuestionAnswered] = useState(null);

  const handleClickOpenReportDialog = () => {
    console.log("clicked");
    setReportResponseMessage("");
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };

  const handleReportReasonChange = (value) => {
    setReportReason(value);
  };

  const handleSubmitReport = async () => {
    const URL =
      `https://0sxyh5cksj.execute-api.us-east-1.amazonaws.com/question/report?id=${questions[currentQuestionIndex].ID}&cert=${questions[currentQuestionIndex].CERT}&report_reason=${reportReason}&ts=` +
      Date.now();

    let response = await axios.get(URL);

    if (response.data) {
      setReportResponseMessage(response.data.msg);
    }

    setReportReason("");
    setTimeout(() => {
      setOpenReportDialog(false);
    }, 3000);
  };

  const answerMultipleAnswerQuestion = (answer) => {
    if (!questions[currentQuestionIndex].hasOwnProperty("USER_ANSWERS")) {
      questions[currentQuestionIndex].USER_ANSWERS = [];
    }

    if (questions[currentQuestionIndex]?.USER_ANSWERS.indexOf(answer) >= 0) {
      questions[currentQuestionIndex]?.USER_ANSWERS.splice(
        questions[currentQuestionIndex]?.USER_ANSWERS.indexOf(answer),
        1
      );
    } else if (
      questions[currentQuestionIndex]?.USER_ANSWERS.indexOf(answer) < 0
    ) {
      questions[currentQuestionIndex]?.USER_ANSWERS.push(answer);
    }
  };

  const answerSingleAnswerQuestion = (answer) => {
    questions[currentQuestionIndex].USER_ANSWERS = [answer];
  };

  const nextQuestion = () => {
    console.log(questions);
    if (currentQuestionIndex < questions.length - 1) {
      if (!questions[currentQuestionIndex].hasOwnProperty("USER_ANSWERS")) {
        questions[currentQuestionIndex].SKIPPED = true;
      } else {
        questions[currentQuestionIndex].SKIPPED = false;
      }
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrQuestionAnswered(questions[currentQuestionIndex + 1].ANSWERED);
    }
  };

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setCurrQuestionAnswered(questions[currentQuestionIndex - 1].ANSWERED);
    }
  };

  const checkAnswer = (question) => {
    if (isCorrect(question)) {
      setCurrQuestionAnswered(true);
      question.SKIPPED = false;
      question.CORRECT = true;
      question.ANSWERED = true;
    } else {
      setCurrQuestionAnswered(true);
      question.SKIPPED = false;
      question.CORRECT = false;
      question.ANSWERED = true;
    }
  };

  const pressSubmitButton = () => {
    let allAnswered = true;
    allAnswered &&
      questions.forEach((question) => {
        if (!question.hasOwnProperty("USER_ANSWERS")) {
          allAnswered = false;
        }
      });
    if (!allAnswered) {
      setAlertMsg(
        "It looks like you've skipped some questions. Are you sure you want to submit?"
      );
      setShowAlert(true);
      return;
    }

    finishExam();
  };

  const finishExam = () => {
    questions.forEach((question) => {
      if (!question.hasOwnProperty("USER_ANSWERS")) {
        question.USER_ANSWERS = [];
        question.SKIPPED = true;
      } else checkAnswer(question);
    });
    setExamFinished(true);
  };

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const arraysEqual = (a1, a2) => {
    a1.sort((a, b) => a.ANSWER_ID - b.ANSWER_ID);
    a2.sort((a, b) => a.ANSWER_ID - b.ANSWER_ID);
    return (
      a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
    );
  };

  const isCorrect = (question) => {
    if (!question.hasOwnProperty("USER_ANSWERS")) {
      question.USER_ANSWERS = [];
    }
    let correct = false;
    correct = arraysEqual(question?.CORRECT_ANSWERS, question?.USER_ANSWERS);

    return correct;
  };

  const computeColor = (question) => {
    if (question.SKIPPED) return "#9e9e9e";
    if (isCorrect(question)) {
      return "#4caf50";
    } else {
      return "#f44336";
    }
  };

  const formatAndUpdateTimeRemaining = () => {
    const minutes = Math.floor(timeRemaining / 60);
    const zerofilledMinutes = ("0000" + minutes).slice(-2);
    const seconds = timeRemaining - minutes * 60;
    const zerofilledSeconds = ("0000" + seconds).slice(-2);

    if (timeRemaining > 0) {
      setTimeRemaining(timeRemaining - 1);
      setMinutes(zerofilledMinutes);
      setSeconds(zerofilledSeconds);
    } else {
      finishExam();
    }
  };

  useEffect(() => {
    if (timed && timeRemaining > -1 && examFinished === false) {
      const interval = setInterval(() => formatAndUpdateTimeRemaining(), 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining, examFinished]);

  return (
    <span style={{ minHeight: "87vh" }}>
      <Card
        sx={{
          maxWidth: 1200,
          backgroundColor: "#333",
          color: "white",
          borderRadius: 3,
          marginTop: 3,
          marginBottom: 50,
          marginRight: 2,
          marginLeft: 2,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent>
          <Typography sx={{ fontSize: 25, textAlign: "center" }} gutterBottom>
            {cert.name} Practice Exam
          </Typography>

          {!examFinished && (
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="warning"
                onClick={handleClickOpenReportDialog}
                sx={{
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Report Question
              </Button>
            </span>
          )}

          <ReportQuestionDialog
            openReportDialog={openReportDialog}
            handleCloseReportDialog={handleCloseReportDialog}
            reportReason={reportReason}
            handleReportReasonChange={handleReportReasonChange}
            handleSubmitReport={handleSubmitReport}
            reportResponseMessage={reportResponseMessage}
          />

          <br />

          <QuestionGrid
            questions={questions}
            currentQuestionIndex={currentQuestionIndex}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
          />

          <br />

          {timeRemaining >= 0 ? (
            <Typography
              sx={{ textAlign: "center" }}
              variant="body1"
              component="div"
            >
              Time Remaining: {minutes}:{seconds}
            </Typography>
          ) : null}

          <hr />
          <br />

          {!examFinished ? (
            <span>
              <span
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="body2" component="div">
                  Question {currentQuestionIndex + 1} of {questions.length}:
                </Typography>

                <Button
                  sx={{ color: "white !important" }}
                  onClick={pressSubmitButton}
                  variant="contained"
                >
                  Submit
                </Button>
              </span>
              {showAlert && (
                <span>
                  <br />
                  <Alert
                    severity="warning"
                    variant="filled"
                    action={
                      <Grid container>
                        <Button
                          onClick={finishExam}
                          size="small"
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={() => setShowAlert(false)}
                        >
                          No
                        </Button>
                      </Grid>
                    }
                  >
                    {alertMsg}
                  </Alert>
                  <br />
                </span>
              )}

              <br />
              <Typography variant="body1" component="div">
                {questions[currentQuestionIndex]?.PROMPT}
              </Typography>
              <br />

              {questions[currentQuestionIndex]?.TYPE === "multiple_answer" ? (
                <Box>
                  <StandardMultipleAnswerQuestionComponent
                    question={questions[currentQuestionIndex]}
                    answerMultipleAnswerQuestion={answerMultipleAnswerQuestion}
                  />

                  <br />
                  {instantFeedback && currQuestionAnswered ? (
                    <QuestionSummaryItem
                      question={questions[currentQuestionIndex]}
                      computeColor={computeColor}
                      handleClickOpenReportDialog={handleClickOpenReportDialog}
                    />
                  ) : null}
                </Box>
              ) : (
                <Box>
                  <StandardSingleAnswerQuestionComponent
                    question={questions[currentQuestionIndex]}
                    answerSingleAnswerQuestion={answerSingleAnswerQuestion}
                  />

                  <br />
                  {instantFeedback && currQuestionAnswered ? (
                    <QuestionSummaryItem
                      question={questions[currentQuestionIndex]}
                      computeColor={computeColor}
                      handleClickOpenReportDialog={handleClickOpenReportDialog}
                    />
                  ) : null}
                </Box>
              )}

              <br />
              <br />

              {instantFeedback && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    checkAnswer(questions[currentQuestionIndex]);
                  }}
                >
                  Check Answer
                </Button>
              )}
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  disabled={currentQuestionIndex === 0}
                  color="warning"
                  variant="contained"
                  onClick={previousQuestion}
                  sx={{ color: "lightgray !important", marginRight: 1 }}
                >
                  Back
                </Button>
                <Button
                  sx={{ color: "white !important" }}
                  disabled={currentQuestionIndex === questions.length - 1}
                  onClick={nextQuestion}
                  color="success"
                  variant="contained"
                >
                  Next
                </Button>
              </div>
            </span>
          ) : (
            <ExamSummary
              questions={questions}
              computeColor={computeColor}
              handleClickOpenReportDialog={handleClickOpenReportDialog}
            />
          )}
        </CardContent>
      </Card>
    </span>
  );
};

export default TestComponent;
