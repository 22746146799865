import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const QuestionSummaryItem = ({
  question,
  computeColor,
  handleClickOpenReportDialog,
}) => {
  return (
    <span key={question.ID} onClick={() => {}}>
      <Box
        sx={{
          backgroundColor: computeColor.bind(this, question),
          borderRadius: 2,
          padding: 1,
        }}
      >
        <span style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            size="small"
            onClick={handleClickOpenReportDialog}
            sx={{
              color: "white",
            }}
          >
            Report Question
          </Button>
        </span>
        <br />
        <Typography variant="body1" component="div">
          {question.PROMPT}
        </Typography>

        <br />
        <Typography variant="body2" component="div">
          You answered:{" "}
        </Typography>
        {question.USER_ANSWERS?.map((answer) => (
          <Typography
            key={answer.ANSWER_TEXT}
            variant="body2"
            component="div"
            sx={{ marginLeft: 2 }}
          >
            - {answer.ANSWER_TEXT}
          </Typography>
        ))}

        <br />
        <Typography variant="body2" component="div">
          Correct answers:{" "}
        </Typography>
        {question.CORRECT_ANSWERS.map((answer) => (
          <Typography
            key={answer.ANSWER_TEXT}
            variant="body2"
            component="div"
            sx={{ marginLeft: 2 }}
          >
            - {answer.ANSWER_TEXT}
          </Typography>
        ))}

        <br />
        <Typography variant="body1" component="div">
          EXPLANATION:
        </Typography>
        <Typography variant="body2" component="div">
          {question.ANSWER_EXPLANATION}
        </Typography>
      </Box>
      <br />
    </span>
  );
};

export default QuestionSummaryItem;
