import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

const ReportQuestionDialog = ({
  openReportDialog,
  handleCloseReportDialog,
  handleSubmitReport,
  reportResponseMessage,
  handleReportReasonChange,
}) => {
  return (
    <Dialog
      open={openReportDialog}
      onClose={handleCloseReportDialog}
      aria-labelledby="report-question-dialog-title"
      aria-describedby="report-question-dialog-description"
    >
      <DialogTitle id="report-question-dialog-title">
        {"Please select report reason below."}
      </DialogTitle>

      {!reportResponseMessage ? (
        <DialogContent>
          <DialogContentText id="report-question-dialog-description">
            By reporting questions that are incorrect or do not live up to our
            standards, you are helping us to improve our content and better your
            experience.
          </DialogContentText>
          <FormControl>
            <br />
            <FormLabel
              sx={{ marginRight: 2, color: "black" }}
              id="report-question-dialog-label"
            >
              Report reason:
            </FormLabel>
            <RadioGroup
              aria-labelledby="report-question-dialog-label"
              defaultValue={false}
              name="exam-parameters-radio-group"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <FormControlLabel
                value={"questionDoesntMakeSense"}
                control={<Radio />}
                label="Question doesn't make sense"
                onClick={handleReportReasonChange.bind(
                  this,
                  "questionDoesntMakeSense"
                )}
              />
              <FormControlLabel
                value={"wrongExam"}
                control={<Radio />}
                label="Question is for the wrong exam"
                onClick={handleReportReasonChange.bind(this, "wrongExam")}
              />
              <FormControlLabel
                value={"answersAreWrong"}
                control={<Radio />}
                label="Answers are wrong"
                onClick={handleReportReasonChange.bind(this, "answersAreWrong")}
              />
              <FormControlLabel
                value={"questionTooDifficult"}
                control={<Radio />}
                label="Question is too difficult"
                onClick={handleReportReasonChange.bind(
                  this,
                  "questionTooDifficult"
                )}
              />
              <FormControlLabel
                value={"questionTooEasy"}
                control={<Radio />}
                label="Question is too easy"
                onClick={handleReportReasonChange.bind(this, "questionTooEasy")}
              />
              <FormControlLabel
                value={"typoInQuestion"}
                control={<Radio />}
                label="Typo in the question"
                onClick={handleReportReasonChange.bind(this, "typoInQuestion")}
              />
              <FormControlLabel
                value={"typoInAnswers"}
                control={<Radio />}
                label="Typo in the answers"
                onClick={handleReportReasonChange.bind(this, "typoInAnswers")}
              />
              <FormControlLabel
                value={"offensiveContent"}
                control={<Radio />}
                label="Offensive or inappropriate content"
                onClick={handleReportReasonChange.bind(
                  this,
                  "offensiveContent"
                )}
              />
              <FormControlLabel
                value={"duplicateQuestion"}
                control={<Radio />}
                label="Duplicate question"
                onClick={handleReportReasonChange.bind(
                  this,
                  "duplicateQuestion"
                )}
              />
              <FormControlLabel
                value={"problemWithFormatting"}
                control={<Radio />}
                label="Problem with the formatting"
                onClick={handleReportReasonChange.bind(
                  this,
                  "problemWithFormatting"
                )}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText id="report-question-dialog-description">
            <Typography variant="body1">{reportResponseMessage}</Typography>
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={handleSubmitReport}>Submit</Button>
        <Button onClick={handleCloseReportDialog} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ReportQuestionDialog;
