import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import QuestionSummaryItem from "./questions/QuestionSummaryItem";
import { useSelector } from "react-redux";

const ExamSummary = ({
  computeColor,
  handleClickOpenReportDialog,
  pastExamIndex,
}) => {
  const exam = useSelector((store) => store.app.pastExams[pastExamIndex]);
  const questions = exam.questions;
  const numCorrect = exam.settings.numCorrect;
  return (
    <Box sx={{ padding: 1, margin: 1 }}>
      <Typography
        sx={{ fontSize: 20, textAlign: "center" }}
        variant="body2"
        component="div"
      >
        Thank you for taking the exam!
      </Typography>
      <br />
      <Typography variant="body1" component="div">
        Total Correct: {numCorrect} / {questions.length}
      </Typography>
      <Typography variant="body1" component="div">
        Your Score: {((numCorrect / questions.length) * 100).toFixed(2)}%
      </Typography>
      <br />
      <Typography variant="body1" component="div">
        Your answers:
      </Typography>
      <br />
      {questions.map((question) => (
        <QuestionSummaryItem
          key={question.ID}
          question={question}
          computeColor={computeColor}
          handleClickOpenReportDialog={handleClickOpenReportDialog}
        />
      ))}
    </Box>
  );
};
export default ExamSummary;
